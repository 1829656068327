import { useTranslator } from '@eo-locale/react';
import { getMonthList } from 'month-list';
import { useMemo } from 'react';
import type { Option } from '../components';

export function useMonthList() {
  const translator = useTranslator();

  const monthList = useMemo(() => {
    const months: Option[] = [
      {
        disabled: true,
        hidden: true,
        label: translator.translate('month'),
        value: '',
      },
    ];

    return months.concat(
      getMonthList('pl').map((month, index) => ({
        label: month,
        value: (index + 1).toString(),
      })),
    );
  }, [translator]);

  return monthList;
}
