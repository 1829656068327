import type { FC } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useContributions } from '../../hooks';
import { Select, SelectProps } from '../atoms';

export const ContributionSelector: FC<Omit<SelectProps, 'name'>> = props => {
  const { setValue } = useFormContext();
  const contributions = useContributions();

  useEffect(() => {
    setValue('contribution', contributions?.[0]?.id);
  }, [contributions, setValue]);

  return (
    <Select
      {...props}
      block
      required
      label="contribution"
      name="contribution"
      options={contributions?.map(contribution => ({
        label: contribution.type,
        value: contribution.id.toString(),
      }))}
    />
  );
};
