import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  AddressField,
  AttachmentField,
  BirthdateField,
  CityField,
  ContributionSelector,
  CountrySelector,
  PhoneNumberField,
  PhotoField,
  StructureSelector,
  VoivodeshipSelector,
} from '..';
import { Checkbox, Input } from '../atoms';
import styles from './UserForm.module.css';

interface Props {
  mode?: 'register' | 'edit';
}

export const UserForm: FC<Props> = ({ mode = 'register' }) => {
  const { watch } = useFormContext();
  const child = watch('child');

  return (
    <>
      <PhotoField mode={mode === 'register' ? 'add' : 'edit'} />
      {mode === 'register' && (
        <Checkbox name="child" className={styles.field}>
          <Text id="i_am_minor" />
        </Checkbox>
      )}
      {child && (
        <>
          <Input
            block
            required={child}
            name="legalFirstName"
            className={styles.field}
            label="legal_first_name"
          />
          <Input
            block
            required={child}
            name="legalLastName"
            className={styles.field}
            label="legal_last_name"
          />
        </>
      )}
      <Input
        block
        required
        className={styles.field}
        label="first_name"
        name="firstName"
      />
      <Input
        block
        className={styles.field}
        label="second_name"
        name="secondName"
      />
      <Input
        block
        required
        className={styles.field}
        label="last_name"
        name="lastName"
      />
      <BirthdateField className={styles.field} />
      <CountrySelector className={styles.field} />
      <Input
        block
        required
        className={styles.field}
        label="pesel"
        mask="99999999999"
        minLength={11}
        name="pesel"
        placeholder="pesel:placeholder"
      />
      <Input
        block
        required
        className={styles.field}
        label="email"
        name="email"
      />
      <PhoneNumberField className={styles.field} />
      <AddressField
        className={styles.field}
        mode={mode === 'register' ? 'full' : 'short'}
      />
      <CityField className={styles.field} />
      <VoivodeshipSelector className={styles.field} />
      {mode === 'register' && <StructureSelector className={styles.field} />}
      {mode === 'register' && <ContributionSelector className={styles.field} />}
      <AttachmentField />
    </>
  );
};
