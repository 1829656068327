import type { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { RoutePath } from '../../config';
import { useAuthContext } from '../../hooks';

export const PrivateRoute: FC = ({ children }) => {
  const { isAuthorized } = useAuthContext();

  if (!isAuthorized) {
    return <Navigate to={RoutePath.Home} />;
  }

  return <>{children}</>;
};
