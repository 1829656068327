import type { FC } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useVoivodeships } from '../../hooks';
import { Select, SelectProps } from '../atoms';

export const VoivodeshipSelector: FC<Omit<SelectProps, 'name'>> = props => {
  const { getValues, setValue } = useFormContext();
  const voivodeships = useVoivodeships();

  useEffect(() => {
    const selected = getValues('voivodeship');
    if (typeof selected !== 'string') {
      setValue('voivodeship', voivodeships?.[0]?.code);
    }
  }, [getValues, voivodeships, setValue]);

  return (
    <Select
      {...props}
      block
      required
      label="voivodeship"
      name="voivodeship"
      options={voivodeships?.map(voivodeship => ({
        label: voivodeship.name,
        value: voivodeship.code,
      }))}
    />
  );
};
