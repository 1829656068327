import { Text } from '@eo-locale/react';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ContactApi, ErrorResponse } from '../../api';
import { RoutePath } from '../../config';
import { useApiErrorHandler, useModal } from '../../hooks';
import { Button, Input, Textarea } from '../atoms';
import styles from './ContactForm.module.css';
import { Modal } from './Modal';

interface FormFields {
  content: string;
  title: string;
}

export const ContactForm: FC = () => {
  const navigate = useNavigate();
  const modal = useModal();
  const { error, mutate, isSuccess } = useMutation<
    void,
    ErrorResponse,
    FormFields
  >(({ content, title }) => {
    return ContactApi.send(title, content);
  }, {});
  const form = useForm<FormFields>({
    defaultValues: {
      title: '',
      content: '',
    },
  });
  const keyMapper = useCallback(() => 'content' as keyof FormFields, []);
  const handleApiError = useApiErrorHandler(form.setError, keyMapper);

  useEffect(() => {
    handleApiError(error);
  }, [error, handleApiError]);

  useEffect(() => {
    if (isSuccess) {
      modal.current?.open();
    }
  }, [isSuccess, modal]);

  const onSubmit: SubmitHandler<FormFields> = async data => {
    mutate(data);
  };

  return (
    <FormProvider {...form}>
      <Modal ref={modal}>
        <p className="text-center">
          <Text id="contact:success" />
        </p>
        <footer className="text-center" style={{ marginTop: 24 }}>
          <Button
            onClick={() => {
              navigate(RoutePath.Profile);
            }}>
            Ok
          </Button>
        </footer>
      </Modal>
      <form
        noValidate
        className={styles.container}
        onSubmit={form.handleSubmit(onSubmit)}>
        <Input
          block
          required
          requiredMark={null}
          className={styles.field}
          label="contact:message_title"
          name="title"
        />
        <Textarea
          block
          required
          requiredMark={null}
          label="contact:message_content"
          name="content"
          rows={5}
        />
        <footer className={styles.footer}>
          <Button type="submit">
            <Text id="contact:submit" />
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};
