import { FC, useCallback, useState } from 'react';
import { LoginForm, LoginFormStep, OuterPage } from '../components';

export const LoginPage: FC = () => {
  const [subtitle, setSubtitle] = useState('login:subtitle');

  const onChangeStep = useCallback((step: LoginFormStep) => {
    setSubtitle(step === 'email' ? 'login:subtitle' : 'login:subtitle_2');
  }, []);

  return (
    <OuterPage isBackVisible title="login:title" subtitle={subtitle}>
      <LoginForm onChangeStep={onChangeStep} />
    </OuterPage>
  );
};
