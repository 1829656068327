import { Text } from '@eo-locale/react';
import { FC } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { AuthApi } from '../../api';
import { useAuthContext } from '../../hooks';
import { Button, CodeField } from '../atoms';
import type { LoginFormFields } from './LoginForm';
import styles from './LoginForm.module.css';

export const LoginCodeStep: FC = () => {
  const { signIn } = useAuthContext();
  const { handleSubmit, watch, setError, setValue } =
    useFormContext<LoginFormFields>();
  const email = watch('email');
  const code = watch('code');

  const onSubmit: SubmitHandler<LoginFormFields> = async ({ code, email }) => {
    try {
      const { meta } = await AuthApi.loginByCode(email, code);
      signIn(meta.access_token);
    } catch (error) {
      setValue('code', '');
      setError('code', {
        type: 'validate',
      });

      console.error(error);
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <CodeField />
      <div>
        <Button className={styles.submit} disabled={code.length === 0}>
          <Text id="login:submit" />
        </Button>
        <button
          className={styles.resend}
          type="button"
          onClick={() => AuthApi.sendCode(email)}>
          <Text id="login:resend_code" />
        </button>
      </div>
    </form>
  );
};
