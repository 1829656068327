import { Numeric } from '@eo-locale/react';
import { FC } from 'react';

interface Props extends Intl.NumberFormatOptions {
  amount: number;
}

export const Money: FC<Props> = ({
  amount,
  currency = 'PLN',
  currencyDisplay = 'code',
  ...shared
}) => {
  return (
    <Numeric
      {...shared}
      currency={currency}
      currencyDisplay={currencyDisplay}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      value={amount}
    />
  );
};
