import { RegisterFormFields } from '../hooks';
import { User } from '../model';
import { HttpClient } from './HttpClient';

export class UserApi {
  static async newsletter(enabled: boolean): Promise<User> {
    const formData = new FormData();
    formData.set('newsletter', enabled ? '1' : '0');
    const { data } = await HttpClient.getInstance().post<User>(
      '/newsletter',
      formData,
    );

    return data;
  }

  static async fetch(): Promise<User> {
    const { data } = await HttpClient.getInstance().get<User>('/self');

    return data;
  }

  static async rejectEdit(): Promise<User> {
    const { data } = await HttpClient.getInstance().get<User>('/reject-edit');

    return data;
  }

  static async edit(params: RegisterFormFields): Promise<void> {
    const formData = new FormData();
    formData.append('first_name', params.firstName);
    if (params.secondName) {
      formData.append('second_name', params.secondName);
    }
    formData.append('last_name', params.lastName);
    formData.append('citizenship', params.country);
    formData.append('pesel', params.pesel);
    formData.append(
      'birthdate',
      `${params.birthYear}-${params.birthMonth.padStart(
        2,
        '0',
      )}-${params.birthDay.padStart(2, '0')}`,
    );
    formData.append('email', params.email);
    formData.append('phone_prefix', params.phonePrefix);
    formData.append('phone', params.phoneNumber);
    formData.append('country', params.country);
    formData.append('city', params.city);
    formData.append('street', params.street);
    formData.append('post_code', params.postCode);
    formData.append('residence_correspondence_address', '1');
    formData.append('voivodeship', params.voivodeship);
    formData.append('newsletter', params.newsletter ? '1' : '0');
    formData.append(
      'picture',
      params.photoCrop ? params.photoCrop : params.photo![0],
    );

    if (params.attachment && params.attachment.length > 0) {
      formData.append('attachment', params.attachment[0]);
    }

    const { data } = await HttpClient.getInstance().post('/edit', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data;
  }
}
