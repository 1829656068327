import { Text } from '@eo-locale/react';
import { FC, useState } from 'react';
import { useQuery } from 'react-query';
import { UserApi } from '../api';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg';
import {
  Button,
  EditPersonalDataForm,
  Modal,
  Portal,
  RejectEditWidget,
} from '../components';
import { UserInformation } from '../components/molecules/UserInformation';
import { useModal } from '../hooks';
import styles from './PersonalDataPage.module.css';

type Mode = 'edit' | 'read';

export const PersonalDataPage: FC = () => {
  const [mode, setMode] = useState<Mode>('read');
  const confirmModal = useModal();
  const { data: user } = useQuery('user', UserApi.fetch);

  const isEditButtonVisible =
    mode === 'read' &&
    (!user?.pending_changes || user.pending_changes.length === 0);

  return (
    <div className={styles.container}>
      <Portal containerId="mobile-header">
        {isEditButtonVisible && (
          <EditIcon onClick={() => confirmModal.current?.open()} />
        )}
      </Portal>
      <Modal ref={confirmModal}>
        <div className="text-center">
          <p className="mb-3">
            <Text id="user:edit_continue" />
          </p>
          <Button
            onClick={() => {
              setMode('edit');
              confirmModal.current?.close();
            }}>
            <Text id="go_to_edit" />
          </Button>
        </div>
      </Modal>
      {isEditButtonVisible && (
        <Button
          className={styles.edit}
          onClick={() => confirmModal.current?.open()}
          theme="secondary">
          <EditIcon />
          <Text id="edit" />
        </Button>
      )}
      {mode === 'read' && <RejectEditWidget />}
      <h1 className={styles.title}>
        <Text id="personal_data:title" />
      </h1>
      <span className="block">
        <Text id="personal_data:id" />
        &nbsp;-&nbsp;<strong>{user?.card_number}</strong>
      </span>
      <h1 className={styles.subtitle}>
        <Text id="personal_data:subtitle" />
      </h1>
      {mode === 'edit' ? (
        <EditPersonalDataForm onSuccess={() => setMode('read')} />
      ) : (
        <UserInformation />
      )}
    </div>
  );
};
