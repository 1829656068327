import { Text } from '@eo-locale/react';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '../../api';
import { RoutePath } from '../../config';
import {
  RegisterFormFields,
  useApiErrorHandler,
  useModal,
  useRegisteForm,
} from '../../hooks';
import { Button, Checkbox } from '../atoms';
import {
  CommonRegulations,
  CommonRegulationsChild,
  NewsletterModal,
  PhotoRegulations,
  PhotoRegulationsChild,
} from '../molecules';
import { UserForm } from '../organisms';
import { Modal } from './Modal';
import styles from './RegisterForm.module.css';

export const RegisterForm: FC = () => {
  const commonRegulationsModal = useModal();
  const photoRegulationsModal = useModal();
  const newsRegulationsModal = useModal();
  const navigate = useNavigate();
  const form = useRegisteForm();
  const { handleSubmit, setError, watch } = form;
  const keyMapper = useCallback(
    (key: string) =>
      key === 'phone' ? 'phoneNumber' : (key as keyof RegisterFormFields),
    [],
  );
  const handleApiError = useApiErrorHandler(setError, keyMapper);
  const child = watch('child');

  const onSubmit: SubmitHandler<RegisterFormFields> = async data => {
    try {
      await AuthApi.register(data);
      navigate(RoutePath.RegisterSuccess);
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <>
      <Modal ref={commonRegulationsModal}>
        {child ? <CommonRegulationsChild /> : <CommonRegulations />}
      </Modal>
      <Modal ref={photoRegulationsModal}>
        {child ? <PhotoRegulationsChild /> : <PhotoRegulations />}
      </Modal>
      <NewsletterModal child={child} ref={newsRegulationsModal} />
      <FormProvider {...form}>
        <form
          noValidate
          className={styles.container}
          onSubmit={handleSubmit(onSubmit)}>
          <UserForm />
          <Checkbox className={styles.checkbox} name="allConsent">
            <Text id="select_all_consents" />
          </Checkbox>
          <div className={styles.checkboxes}>
            <Checkbox required className={styles.checkbox} name="regulations">
              Zapoznałem się i akceptuje{' '}
              <span
                className="underline"
                onClick={e => {
                  e.preventDefault();
                  commonRegulationsModal.current?.open();
                }}>
                OGÓLNĄ KLAUZUlĘ INFORMACYJNA DLA CZŁONKÓW PTTK
              </span>
            </Checkbox>
            <Checkbox
              required
              className={styles.checkbox}
              name="regulationsSecondary">
              {child ? (
                <>
                  Wyrażam zgodę na wykorzystywanie{' '}
                  <span
                    className="underline"
                    onClick={e => {
                      e.preventDefault();
                      photoRegulationsModal.current?.open();
                    }}>
                    WIZERUNKU DZIECKA
                  </span>
                </>
              ) : (
                <>
                  Wyrażam zgodę na wykorzystywanie{' '}
                  <span
                    className="underline"
                    onClick={e => {
                      e.preventDefault();
                      photoRegulationsModal.current?.open();
                    }}>
                    WIZERUNKU
                  </span>
                </>
              )}
            </Checkbox>
            <Checkbox className={styles.checkbox} name="newsletter">
              Chcę otrzymywac{' '}
              <span
                className="underline"
                onClick={e => {
                  e.preventDefault();
                  newsRegulationsModal.current?.open();
                }}>
                NEWSLETTER
              </span>
            </Checkbox>
            <span className={styles.helper}>
              <Text id="required_fields" />
            </span>
          </div>
          <Button className={styles.submit} type="submit">
            <Text id="register:submit" />
          </Button>
        </form>
      </FormProvider>
    </>
  );
};
