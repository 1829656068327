export function isNotEmptyArray<T>(value: unknown): value is [T, ...T[]] {
  return Array.isArray(value) && value.length > 0;
}

export function noop() {}

export function getDaysInMonth(month: number, year: number): number {
  const daysInMonth = new Date(year, month, 0).getDate();

  return daysInMonth;
}
