import { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface Props {
  containerId?: string;
}

export const Portal: FC<Props> = ({ children, containerId }) => {
  const [element, setElement] = useState<Element | null>(null);

  useEffect(() => {
    setElement(
      containerId ? document.getElementById(containerId) : document.body,
    );
  }, [containerId]);

  return element ? ReactDOM.createPortal(children, element) : null;
};
