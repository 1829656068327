import { Text } from '@eo-locale/react';
import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Logo from '../../assets/logo.svg';
import { RoutePath } from '../../config';
import { useAuthContext } from '../../hooks';
import styles from './OuterPage.module.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isBackVisible?: boolean;
  subtitle?: string;
  title: string;
}

export const OuterPage: FC<Props> = ({
  children,
  className,
  isBackVisible = false,
  title,
  subtitle,
  ...rest
}) => {
  const { isAuthorized } = useAuthContext();
  const navigate = useNavigate();

  if (isAuthorized) {
    return <Navigate to={RoutePath.Profile} />;
  }

  return (
    <div className={styles.container}>
      {isBackVisible && (
        <button
          className={styles.back}
          onClick={() => navigate(RoutePath.Home)}
        />
      )}
      <main className={classNames(styles.content, className)} {...rest}>
        <img
          alt="Logo"
          className={styles.logo}
          src={Logo}
          width={81}
          height={81}
          onClick={() => navigate(RoutePath.Home)}
        />
        <h1 className={styles.title}>
          <Text id={title} />
        </h1>
        {!!subtitle && (
          <h2 className={styles.subtitle}>
            <Text id={subtitle} />
          </h2>
        )}
        {children}
      </main>
      <footer className={styles.footer} />
    </div>
  );
};
