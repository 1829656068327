import { UserContribution } from '../model';
import { HttpClient } from './HttpClient';

interface ContributionsResponse {
  data: UserContribution[];
}

export class ContributionsApi {
  static async fetch(): Promise<UserContribution[]> {
    const { data } = await HttpClient.getInstance().get<ContributionsResponse>(
      '/member/collections',
    );

    return data.data;
  }
}
