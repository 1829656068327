import { TranslationsProvider } from '@eo-locale/react';
import type { FC } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthContextProvider, ProfileLayout } from './components';
import { RoutePath } from './config';
import {
  CardPage,
  CardStatusPage,
  CheckoutPage,
  ContactPage,
  ContributionsPage,
  HistoryPage,
  HomePage,
  LoginPage,
  MembershipPage,
  PaymentFailedPage,
  PaymentResultPage,
  PaymentSuccessfulPage,
  PersonalDataPage,
  RegisterPage,
  RegisterSuccessPage,
} from './pages';
import plTranslations from './translations/pl.json';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const locales = [
  {
    language: 'pl',
    messages: plTranslations,
  },
];

export const App: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <TranslationsProvider language="pl" locales={locales}>
          <BrowserRouter>
            <Routes>
              <Route path={RoutePath.Home} element={<HomePage />} />
              <Route path={RoutePath.Login} element={<LoginPage />} />
              <Route path={RoutePath.Register} element={<RegisterPage />} />
              <Route
                path={RoutePath.RegisterSuccess}
                element={<RegisterSuccessPage />}
              />

              <Route path={RoutePath.Profile} element={<ProfileLayout />}>
                <Route
                  index
                  element={<Navigate replace to={RoutePath.PersonalData} />}
                />
                <Route
                  path={RoutePath.PersonalData}
                  element={<PersonalDataPage />}
                />
                <Route
                  path={RoutePath.Membership}
                  element={<MembershipPage />}
                />
                <Route
                  path={RoutePath.Contributions}
                  element={<ContributionsPage />}
                />
                <Route path={RoutePath.History} element={<HistoryPage />} />
                <Route
                  path="*"
                  element={<Navigate replace to={RoutePath.PersonalData} />}
                />
              </Route>

              <Route path={RoutePath.Card} element={<CardPage />} />
              <Route path={RoutePath.CardStatus} element={<CardStatusPage />} />
              <Route path={RoutePath.Contact} element={<ContactPage />} />

              <Route path={RoutePath.Checkout} element={<CheckoutPage />} />
              <Route
                path={RoutePath.PaymentResult}
                element={<PaymentResultPage />}
              />
              <Route
                path={RoutePath.PaymentSuccessful}
                element={<PaymentSuccessfulPage />}
              />
              <Route
                path={RoutePath.PaymentFailed}
                element={<PaymentFailedPage />}
              />

              <Route
                path="*"
                element={<Navigate replace to={RoutePath.Home} />}
              />
            </Routes>
          </BrowserRouter>
        </TranslationsProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
};
