import { Text } from '@eo-locale/react';
import { FC, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../../assets/icons/burger.svg';
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { ReactComponent as ExitIcon } from '../../assets/icons/exit.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import Logo from '../../assets/logo.svg';
import { RoutePath } from '../../config';
import { useAuthContext, useIsDesktop } from '../../hooks';
import { PrivateRoute } from '../atoms';
import styles from './InnerPage.module.css';
import { MembershipStatus } from './MembershipStatus';
import { SideBar } from './SideBar';

export const InnerPage: FC = ({ children }) => {
  const { signOut } = useAuthContext();
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (isDesktop) {
      setVisible(false);
    }
  }, [isDesktop]);

  return (
    <PrivateRoute>
      {visible && <SideBar onClose={() => setVisible(false)} />}
      <div className={styles.container}>
        <header className={styles.header}>
          <div className="flex-1 flex items-center justify-between">
            <img
              alt="Logo"
              className={styles.logo}
              onClick={() => navigate(RoutePath.Profile)}
              src={Logo}
            />
            <nav className={styles.menu}>
              <NavLink className={styles.item} to={RoutePath.Profile}>
                <UserIcon />
                <Text id="profile" />
              </NavLink>
              <NavLink className={styles.item} to={RoutePath.Card}>
                <CardIcon />
                <Text id="card" />
              </NavLink>
              <NavLink className={styles.item} to={RoutePath.Contact}>
                <MessageIcon />
                <Text id="contact" />
              </NavLink>
            </nav>
            <MembershipStatus />
            <button className={styles.exit} onClick={signOut} type="button">
              <ExitIcon />
              <Text id="exit" />
            </button>
            <div className="md:hidden flex items-center" style={{ gap: 26 }}>
              <span id="mobile-header" />
              <button
                className={styles.burger}
                onClick={() => setVisible(true)}
                type="button">
                <MenuIcon />
              </button>
            </div>
          </div>
        </header>
        {children}
        <footer className={styles.footer} />
      </div>
    </PrivateRoute>
  );
};
