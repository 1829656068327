import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { UserApi } from '../api';

export function useMembershipExpiryDate() {
  const { data: user } = useQuery('user', UserApi.fetch);

  const expiryDate = useMemo(() => {
    if (!user || !user.expire) {
      return undefined;
    }

    try {
      return new Date(user.expire);
    } catch (error) {
      console.error(error);
    }
  }, [user]);

  let isExpirySoon = false;

  if (expiryDate) {
    const now = new Date();
    const start = new Date(expiryDate.getFullYear(), 10, 15); // 15th November
    const end = new Date(expiryDate.getFullYear() + 1, 7, 1); // 1st August
    isExpirySoon = now > start && now < end;
  }

  return {
    expiryDate,
    isExpirySoon,
    isValid: Boolean(user?.is_valid),
  };
}
