import { createContext, FC, useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AuthApi, HttpClient, UserApi } from '../../api';
import { noop } from '../../utils';

interface AuthContextValue {
  isAuthorized: boolean;
  signIn(token: string): void;
  signOut(): void;
}

export const AuthContext = createContext<AuthContextValue>({
  isAuthorized: false,
  signIn: noop,
  signOut: noop,
});

export const AuthContextProvider: FC = ({ children }) => {
  const { refetch, remove } = useQuery('user', UserApi.fetch, {
    enabled: false,
  });
  const [authorized, setAuthorized] = useState<boolean>();

  const signOut = useCallback(async () => {
    await AuthApi.logOut();
    localStorage.removeItem('token');
    HttpClient.clearToken();
    setAuthorized(false);
  }, []);

  const signIn = useCallback(
    async (token: string) => {
      HttpClient.setToken(token);

      try {
        // Remove previous result from cache, because it could be failed
        remove();
        const { isError } = await refetch();
        if (isError) {
          localStorage.removeItem('token');
          HttpClient.clearToken();
          setAuthorized(false);
          return;
        }
        localStorage.setItem('token', token);
        setAuthorized(true);
      } catch (error) {
        console.error(error);
        localStorage.removeItem('token');
        HttpClient.clearToken();
        setAuthorized(false);
      }
    },
    [refetch, remove],
  );

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      signIn(token);
    } else {
      setAuthorized(false);
    }
  }, [signIn]);

  if (authorized === undefined) {
    return null;
  }

  return (
    <AuthContext.Provider value={{ isAuthorized: authorized, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};
