import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { AuthApi } from '../../api';
import { useApiErrorHandler } from '../../hooks';
import { Button } from '../atoms';
import { Input } from '../atoms/Input';
import type { LoginFormFields } from './LoginForm';
import styles from './LoginForm.module.css';

interface Props {
  onSuccess(): void;
}

export const LoginEmailStep: FC<Props> = ({ onSuccess }) => {
  const { handleSubmit, setError } = useFormContext<LoginFormFields>();
  const handleApiError = useApiErrorHandler(setError);

  const onSubmit: SubmitHandler<LoginFormFields> = async ({ email }) => {
    try {
      await AuthApi.sendCode(email);
      onSuccess();
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      <Input
        block
        required
        className={styles.email}
        name="email"
        label="email"
        type="email"
      />
      <Button className={styles.submit}>
        <Text id="login:send_code" />
      </Button>
    </form>
  );
};
