import { Text, useTranslator } from '@eo-locale/react';
import classNames from 'classnames';
import { FC, InputHTMLAttributes, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import styles from './Input.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  block?: boolean;
  label?: string;
  mask?: string;
  name: string;
  requiredMark?: ReactNode;
}

export const Input: FC<Props> = ({
  block = false,
  children,
  className,
  label,
  mask,
  maxLength,
  minLength,
  name,
  placeholder,
  required = false,
  requiredMark = '*',
  type = 'text',
  ...rest
}) => {
  const {
    control,
    formState: { errors },
    register,
  } = useFormContext();
  const translator = useTranslator();

  const errorMessage = errors[name]?.message;

  return (
    <label
      className={classNames(
        styles.container,
        {
          [styles.block]: block,
        },
        className,
      )}>
      {!!label && (
        <span className={styles.label}>
          <Text id={label} />
          {required && requiredMark}
        </span>
      )}
      {mask ? (
        <Controller
          control={control}
          name={name}
          rules={{
            maxLength,
            minLength,
            required,
          }}
          render={({ field, fieldState }) => {
            return (
              <InputMask
                {...field}
                className={classNames(styles.input, {
                  [styles.invalid]: Boolean(fieldState.error),
                })}
                mask={mask}
                maskPlaceholder={null}
                placeholder={
                  placeholder ? translator.translate(placeholder) : undefined
                }
                value={field.value ?? ''}
                required={required}
              />
            );
          }}
        />
      ) : (
        <input
          {...rest}
          {...register(name, {
            required,
            minLength,
            maxLength,
          })}
          className={classNames(styles.input, {
            [styles.invalid]: Boolean(errors[name]),
          })}
          required={required}
          placeholder={
            placeholder ? translator.translate(placeholder) : undefined
          }
          type={type}
        />
      )}
      {!!errorMessage && <span className={styles.error}>{errorMessage}</span>}
    </label>
  );
};
