import type { FC } from 'react';
import { OuterPage } from '../components';

export const RegisterSuccessPage: FC = () => {
  return (
    <OuterPage
      isBackVisible
      title="register_success:title"
      subtitle="register_success:subtitle"
    />
  );
};
