export enum RoutePath {
  Card = '/card',
  CardStatus = '/card-status/:userId',
  Checkout = '/checkout',
  Contact = '/contact',
  Contributions = '/profile/contributions',
  History = '/profile/history',
  Home = '/',
  Login = '/login',
  Membership = '/profile/membership',
  PaymentFailed = '/payment/failed',
  PaymentResult = '/payment/payment_result',
  PaymentSuccessful = '/payment/successful',
  PersonalData = '/profile/settings',
  Profile = '/profile',
  Register = '/register',
  RegisterSuccess = '/register-success',
}
