import { forwardRef } from 'react';
import { Modal, ModalRef } from '..';
import { NewsRegulations } from './NewsRegulations';
import { NewsRegulationsChild } from './NewsRegulationsChild';

interface Props {
  child: boolean;
}

export const NewsletterModal = forwardRef<ModalRef, Props>((props, ref) => {
  return (
    <Modal ref={ref}>
      {props.child ? <NewsRegulationsChild /> : <NewsRegulations />}
    </Modal>
  );
});
