import { useQuery } from 'react-query';
import { CardApi } from '../api';

export function useCard(userId?: string) {
  const { data: card } = useQuery(
    ['card', userId],
    () => CardApi.data(userId!),
    {
      enabled: !!userId,
    },
  );

  return card;
}
