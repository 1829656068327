import { PaymentInformation } from '../model';
import { HttpClient } from './HttpClient';

export class PaymentsApi {
  static async pay(id: string): Promise<{
    redirect_url: string;
  }> {
    const formData = new FormData();
    formData.append('collection_id', id);

    const { data } = await HttpClient.getInstance().post(
      '/payments/pay',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    return data;
  }

  static async getPaymentInformation(
    orderId: string,
  ): Promise<PaymentInformation> {
    const formData = new FormData();
    formData.append('OrderID', orderId);

    const { data } = await HttpClient.getInstance().post(
      '/payments/bluemedia/return',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    return data;
  }
}
