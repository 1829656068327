import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, OuterPage } from '../components';
import { RoutePath } from '../config';
import styles from './HomePage.module.css';

export const HomePage: FC = () => {
  const navigate = useNavigate();

  return (
    <OuterPage className={styles.container} title="home:title">
      <section className="flex-1">
        <div className={styles.actions}>
          <Button
            className={styles.action}
            onClick={() => navigate(RoutePath.Login)}>
            <Text id="signin" />
          </Button>
          <Button
            className={styles.action}
            onClick={() => navigate(RoutePath.Register)}
            theme="secondary">
            <Text id="signup" />
          </Button>
        </div>
      </section>
      <footer className={styles.footer}>
        <h5 className={styles.subtitle}>
          <Text id="private_policy:title" />
        </h5>
        <p className={styles.description}>
          <Text id="private_policy:description" />
        </p>
      </footer>
    </OuterPage>
  );
};
