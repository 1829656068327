import { useTranslator } from '@eo-locale/react';
import { useMemo } from 'react';
import type { Option } from '../components';

export function useMonthDayList() {
  const translator = useTranslator();

  const dayList = useMemo(() => {
    const days: Option[] = [
      {
        disabled: true,
        hidden: true,
        label: translator.translate('day'),
        value: '',
      },
    ];

    return days.concat(
      Array.from(Array(31).keys()).map(item => ({
        label: (item + 1).toString(),
        value: (item + 1).toString(),
      })),
    );
  }, [translator]);

  return dayList;
}
