import { FC } from 'react';
import { OuterPage, RegisterForm } from '../components';

export const RegisterPage: FC = () => {
  return (
    <OuterPage
      isBackVisible
      title="register:title"
      subtitle="register:subtitle">
      <RegisterForm />
    </OuterPage>
  );
};
