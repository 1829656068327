import { Text, useTranslator } from '@eo-locale/react';
import classNames from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../config';
import { useMembershipExpiryDate } from '../../hooks';
import { Button } from '../atoms';

export const MembershipStatus: FC = () => {
  const translator = useTranslator();
  const { expiryDate, isExpirySoon, isValid } = useMembershipExpiryDate();
  const navigate = useNavigate();

  const isCheckoutButtonVisible = isExpirySoon || !isValid;

  return (
    <div className="hidden md:flex items-center gap-2 mr-6">
      {isCheckoutButtonVisible && (
        <Button
          className="px-2.5"
          onClick={() => {
            navigate(RoutePath.Checkout);
          }}>
          <Text id="membership:make_payment" />
        </Button>
      )}
      <span className="block text-base">
        <Text id="membership:status" />
        <span
          className={classNames(
            'block',
            isValid ? 'text-green-100' : 'text-red-100',
          )}>
          {expiryDate ? (
            <Text
              date={translator.formatDate(expiryDate)}
              id="membership:valid_until"
            />
          ) : (
            <Text id="membership:unpaid" />
          )}
        </span>
      </span>
    </div>
  );
};
