import { RegisterFormFields } from '../hooks';
import { BooleanString } from '../model';
import { HttpClient } from './HttpClient';

interface SendCodeResponse {
  error: boolean;
}

interface AuthResponse {
  card_number: string;
  duplicate: null;
  old_card_number: string | null;
  child: BooleanString;
  legal_first_name: string;
  legal_last_name: string;
  first_name: string;
  second_name: string;
  last_name: string;
  citizenship: string;
  pesel: string;
  birthdate: string;
  email: string;
  phone: string;
  country: string;
  city: string;
  street: string;
  post_code: string;
  residence_correspondence_address: BooleanString;
  country_correspondence: string;
  city_correspondence: string;
  street_correspondence: string;
  post_code_correspondence: string;
  created_at: string;
  updated_at: string;
  phone_prefix: string;
  voivodeship: string;
  voivodeship_other: string;
  require_approve: number;
  newsletter: BooleanString;
  status: null;
  uuid: null;
  is_valid: boolean;
  meta: {
    access_token: string;
  };
}

export class AuthApi {
  static async register(params: RegisterFormFields): Promise<AuthResponse> {
    const formData = new FormData();
    formData.append('child', params.child ? '1' : '0');
    if (params.legalFirstName) {
      formData.append('legal_first_name', params.legalFirstName);
    }
    if (params.legalLastName) {
      formData.append('legal_last_name', params.legalLastName);
    }
    formData.append('first_name', params.firstName);
    if (params.secondName) {
      formData.append('second_name', params.secondName);
    }
    formData.append('last_name', params.lastName);
    formData.append('citizenship', params.country);
    formData.append('pesel', params.pesel);
    formData.append(
      'birthdate',
      `${params.birthYear}-${params.birthMonth.padStart(
        2,
        '0',
      )}-${params.birthDay.padStart(2, '0')}`,
    );
    formData.append('email', params.email);
    formData.append('phone_prefix', params.phonePrefix);
    formData.append('phone', params.phoneNumber);
    formData.append('country', params.country);
    formData.append('city', params.city);
    formData.append('street', `${params.street} ${params.house}`);
    formData.append('post_code', params.postCode);
    formData.append('residence_correspondence_address', '1');
    formData.append('voivodeship', params.voivodeship);
    formData.append('newsletter', params.newsletter ? '1' : '0');
    formData.append(
      'picture',
      params.photoCrop ? params.photoCrop : params.photo![0],
    );
    formData.append('structure_id', params.structure);
    formData.append('collection_id', params.contribution);

    if (params.attachment && params.attachment.length > 0) {
      formData.append('attachment', params.attachment[0]);
    }

    const { data } = await HttpClient.getInstance().post<AuthResponse>(
      '/register',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    );

    return data;
  }

  static async loginByCode(email: string, code: string): Promise<AuthResponse> {
    const { data } = await HttpClient.getInstance().post<AuthResponse>(
      '/login-by-code',
      { code, email },
    );

    return data;
  }

  static async sendCode(email: string): Promise<SendCodeResponse> {
    const { data } = await HttpClient.getInstance().post<SendCodeResponse>(
      '/send-code',
      { email },
    );

    return data;
  }

  static async logOut(): Promise<void> {
    await HttpClient.getInstance().get('/logout');
  }
}
