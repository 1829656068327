import { Text } from '@eo-locale/react';
import { FC, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UserApi } from '../../api';
import {
  useCountries,
  useModal,
  useUserContributions,
  useUserMembership,
  useVoivodeships,
} from '../../hooks';
import { usePhoto } from '../../hooks/usePhoto';
import { Checkbox } from '../atoms';
import { NewsletterModal } from './NewsletterModal';
import styles from './UserInformation.module.css';

export const UserInformation: FC = () => {
  const queryClient = useQueryClient();
  const newsletterModal = useModal();
  const countries = useCountries();
  const voivodeships = useVoivodeships();
  const { mutate } = useMutation('newsletter', UserApi.newsletter, {
    onSuccess() {
      queryClient.invalidateQueries('user');
    },
  });
  const { active: userMembership } = useUserMembership();
  const { userContribution } = useUserContributions();
  const { data: user } = useQuery('user', UserApi.fetch);
  const form = useForm<{ newsletter: boolean }>({
    defaultValues: {
      newsletter: Boolean(user?.newsletter),
    },
  });
  const photo = usePhoto(user?.image);
  const newsletter = form.watch('newsletter');

  useEffect(() => {
    if (newsletter !== Boolean(user?.newsletter)) {
      mutate(newsletter);
    }
  }, [mutate, newsletter, user]);

  if (!user) {
    return null;
  }

  return (
    <>
      <img
        alt="User"
        src={photo}
        className={styles.photo}
        width={93}
        height={118}
      />
      <ul className={styles.container}>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="first_name" />
          </span>
          <strong className={styles.value}>{user.first_name}</strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="second_name" />
          </span>
          <strong className={styles.value}>{user.second_name ?? '-'}</strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="last_name" />
          </span>
          <strong className={styles.value}>{user.last_name}</strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="birth_date" />
          </span>
          <strong className={styles.value}>{user.birthdate}</strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="country" />
          </span>
          <strong className={styles.value}>
            {countries?.find(country => country.code === user.country)?.name}
          </strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="pesel" />
          </span>
          <strong className={styles.value}>{user.pesel}</strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="email" />
          </span>
          <strong className={styles.value}>{user.email}</strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="phone" />
          </span>
          <strong className={styles.value}>
            {user.phone_prefix}&nbsp;{user.phone}
          </strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="street" />
          </span>
          <strong className={styles.value}>{user.street}</strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="city" />
          </span>
          <strong className={styles.value}>
            {user.post_code}&nbsp;{user.city}
          </strong>
        </li>
        <li className={styles.field}>
          <span className={styles.title}>
            <Text id="voivodeship" />
          </span>
          <strong className={styles.value}>
            {
              voivodeships?.find(
                voivodeship => voivodeship.code === user.voivodeship,
              )?.name
            }
          </strong>
        </li>
        {userMembership.map((membership, index) => (
          <li className={styles.field} key={`membership-${index}`}>
            <span className={styles.title}>{membership.type_name}</span>
            <strong className={styles.value}>{membership.name}</strong>
          </li>
        ))}
        {userContribution && (
          <li className={styles.field}>
            <span className={styles.title}>
              <Text id="contribution" />
            </span>
            <strong className={styles.value}>{userContribution.type}</strong>
          </li>
        )}
      </ul>
      <NewsletterModal child={user.child === '1'} ref={newsletterModal} />
      <FormProvider {...form}>
        <Checkbox name="newsletter">
          Chcę otrzymywac{' '}
          <span
            onClick={e => {
              e.preventDefault();
              newsletterModal.current?.open();
            }}
            className="underline">
            Newsletter
          </span>
        </Checkbox>
      </FormProvider>
    </>
  );
};
