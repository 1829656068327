import classNames from 'classnames';
import type { FC, HTMLAttributes } from 'react';
import { Input } from '../atoms';
import styles from './AddressField.module.css';

export type AddressFieldMode = 'full' | 'short';

interface Props extends HTMLAttributes<HTMLDivElement> {
  mode?: AddressFieldMode;
}

export const AddressField: FC<Props> = ({
  children,
  className,
  mode = 'full',
  ...rest
}) => {
  return (
    <div className={classNames(styles.container, className)} {...rest}>
      <Input required className="flex-1" name="street" label="street" />
      {mode === 'full' && (
        <Input
          required
          className={styles.small}
          name="house"
          label="house_apartment"
        />
      )}
    </div>
  );
};
