import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Portal } from '../atoms';
import styles from './Modal.module.css';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';

export interface ModalRef {
  close(): void;
  open(): void;
}

export const Modal = forwardRef<
  ModalRef,
  {
    children?: ReactNode;
  }
>(({ children }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), []);

  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (e.key.toLowerCase() === 'escape') {
        close();
      }
    },
    [close],
  );

  useEffect(() => {
    const root = document.getElementById('root')!;

    if (isOpen) {
      root.style.filter = 'blur(5px)';
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      root.style.filter = '';
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = '';
    };
  }, [handleEscape, isOpen]);

  useImperativeHandle(ref, () => ({
    close,
    open: () => setIsOpen(true),
  }));

  if (!isOpen) {
    return null;
  }

  return (
    <Portal>
      <div className={styles.overlay} onClick={close}>
        <div className={styles.content} onClick={e => e.stopPropagation()}>
          <header className={styles.header}>
            <button type="button" onClick={close} className={styles.close}>
              <CloseIcon />
            </button>
          </header>
          {children}
        </div>
      </div>
    </Portal>
  );
});
