import { Text } from '@eo-locale/react';
import { FC } from 'react';
import { InnerPageSection, InnerPageTitle, Table } from '../components';
import { useUserMembership } from '../hooks';

export const MembershipPage: FC = () => {
  const { groups } = useUserMembership();

  return (
    <>
      {groups.map((group, index) => {
        if (group.list.length === 0) {
          return null;
        }

        return (
          <InnerPageSection key={`group-${index}`}>
            <InnerPageTitle>
              <Text id={group.title} />
            </InnerPageTitle>
            <Table>
              <thead>
                <tr>
                  <th>
                    <Text id="membership:title" />
                  </th>
                  <th>
                    <Text id="membership:date_start" />
                  </th>
                  <th>
                    <Text id="membership:last_change" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {group.list.map((membership, index) => (
                  <tr key={index}>
                    <td>{membership.name}</td>
                    <td>{membership.date}</td>
                    <td>{membership.updated_at}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </InnerPageSection>
        );
      })}
    </>
  );
};
