import { Text } from '@eo-locale/react';
import classNames from 'classnames';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import Logo from '../../assets/logo.svg';
import styles from './PaymentPage.module.css';

interface Props {
  title: string;
  isBackButtonVisible?: boolean;
  subtitle?: string;
}

export const PaymentPage: FC<Props> = ({
  children,
  title,
  isBackButtonVisible = false,
  subtitle,
}) => {
  const navigate = useNavigate();

  return (
    <div
      className={classNames(
        styles.container,
        'p-2 flex items-center justify-center min-h-screen',
      )}>
      <main className="relative py-10 px-2 md:px-10 bg-white rounded-md max-w-[855px] lg:min-w-[855px] text-center">
        {isBackButtonVisible && (
          <button
            type="button"
            className="absolute top-2 right-2"
            onClick={() => {
              navigate(-1);
            }}>
            <CloseIcon />
          </button>
        )}
        <img
          alt="Logo"
          className="mb-4 mx-auto"
          height={81}
          src={Logo}
          width={81}
        />
        <h1 className="mb-4 text-2xl">
          <Text id={title} />
        </h1>
        {subtitle && (
          <h2 className="mb-4 text-base">
            <Text id={subtitle} />
          </h2>
        )}
        {children}
      </main>
    </div>
  );
};
