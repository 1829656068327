import { FC, useEffect } from 'react';
import styles from './Overlay.module.css';
import { Portal } from './Portal';

interface Props {
  onClick?(): void;
}

export const Overlay: FC<Props> = ({ onClick }) => {
  useEffect(() => {
    const root = document.getElementById('root')!;

    root.style.filter = 'blur(5px)';

    return () => {
      root.style.filter = '';
    };
  });

  return (
    <Portal>
      <div className={styles.overlay} onClick={onClick} />
    </Portal>
  );
};
