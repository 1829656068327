import classNames from 'classnames';
import type { ButtonHTMLAttributes, FC } from 'react';
import styles from './Button.module.css';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  block?: boolean;
  theme?: 'primary' | 'secondary';
}

export const Button: FC<Props> = ({
  block = false,
  children,
  className,
  theme = 'primary',
  ...rest
}) => {
  return (
    <button
      className={classNames(
        styles.button,
        {
          [styles.block]: block,
          [styles.primary]: theme === 'primary',
          [styles.secondary]: theme === 'secondary',
        },
        className,
      )}
      {...rest}>
      {children}
    </button>
  );
};
