import { useCallback, useEffect, useState } from 'react';
import { HttpClient } from '../api';

export function usePhoto(image?: string): string | undefined {
  const [photo, setPhoto] = useState<string>();

  const fetchPhoto = useCallback(async () => {
    if (!image) {
      return;
    }

    const imageUrl = new URL(image);
    const { data: response } = await HttpClient.getInstance().get(
      `${imageUrl.pathname.replace('/api', '')}${imageUrl.search}`,
      {
        responseType: 'blob',
      },
    );
    const urlObject = URL.createObjectURL(response);
    setPhoto(urlObject);

    return () => {
      URL.revokeObjectURL(urlObject);
    };
  }, [image]);

  useEffect(() => {
    fetchPhoto();
  }, [fetchPhoto]);

  return photo;
}
