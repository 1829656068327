import { Text } from '@eo-locale/react';
import classNames from 'classnames';
import { FC, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { ReactComponent as UploadIcon } from '../../assets/icons/upload.svg';
import styles from './AttachmentField.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  invalid?: boolean;
}

export const AttachmentField: FC<Props> = ({
  children,
  className,
  invalid = false,
  ...rest
}) => {
  const { register, watch } = useFormContext();
  const attachment: FileList | undefined = watch('attachment');

  return (
    <div className={classNames(styles.container, className)}>
      <label
        htmlFor="attachment"
        className={classNames(styles.label, {
          [styles.invalid]: invalid,
        })}>
        <UploadIcon />
        <Text id="upload_file" />
      </label>
      <input
        {...rest}
        {...register('attachment')}
        accept=".jpg, .png, .jpeg, .pdf"
        id="attachment"
        className={styles.input}
        type="file"
      />
      <span className={styles.description}>
        {attachment && attachment.length > 0 ? (
          attachment[0].name
        ) : (
          <Text id="attachment:description" />
        )}
      </span>
    </div>
  );
};
