import classNames from 'classnames';
import type { FC, TableHTMLAttributes } from 'react';
import styles from './Table.module.css';

export const Table: FC<TableHTMLAttributes<HTMLTableElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <table className={classNames(styles.table, className)} {...rest}>
      {children}
    </table>
  );
};
