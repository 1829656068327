import { Text } from '@eo-locale/react';
import { FC, HTMLAttributes, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIsDesktop, useMonthDayList, useMonthList } from '../../hooks';
import { getDaysInMonth } from '../../utils';
import { Input, Select } from '../atoms';
import styles from './BirthdateField.module.css';

export const BirthdateField: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  const isDesktop = useIsDesktop();
  const { setError, clearErrors, watch } = useFormContext();
  const [day, month, year] = watch(['birthDay', 'birthMonth', 'birthYear']);

  useEffect(() => {
    if (day === '') {
      return;
    }

    if (day > getDaysInMonth(Number(month), Number(year))) {
      setError('birthDay', {
        type: 'validate',
      });
    } else {
      clearErrors('birthDay');
    }
  }, [day, clearErrors, month, year, setError]);

  const dayList = useMonthDayList();
  const monthList = useMonthList();

  return (
    <div className={className} {...rest}>
      <span className={styles.label}>
        <Text id="birth_date" />*
      </span>
      <div className={styles.container}>
        <Select
          required
          className={styles.item}
          name="birthDay"
          options={dayList}
        />
        <Select
          required
          className={styles.item}
          name="birthMonth"
          options={monthList}
        />
        <Input
          required
          className={styles.item}
          maxLength={4}
          name="birthYear"
          placeholder="year"
          type={isDesktop ? 'number' : 'tel'}
          minLength={4}
        />
      </div>
    </div>
  );
};
