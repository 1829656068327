import classNames from 'classnames';
import type { FC, HTMLAttributes } from 'react';
import styles from './InnerPageContent.module.css';

export const InnerPageContent: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <main className={classNames(styles.container, className)} {...rest}>
      {children}
    </main>
  );
};
