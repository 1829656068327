import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PaymentsApi } from '../api';
import { Loader, PaymentPage } from '../components';
import { RoutePath } from '../config';

export const PaymentResultPage: FC = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('OrderID');
  const navigate = useNavigate();
  const { data } = useQuery(
    'getPaymentStatus',
    () => PaymentsApi.getPaymentInformation(orderId!),
    {
      enabled: !!orderId,
      refetchInterval: 5_000,
    },
  );

  useEffect(() => {
    if (data?.status === 'CONFIRMED') {
      navigate(RoutePath.PaymentSuccessful);
    }
  }, [data, navigate]);

  return (
    <PaymentPage
      title="payment_result:title"
      subtitle="payment_result:subtitle">
      <Loader />
    </PaymentPage>
  );
};
