import classNames from 'classnames';
import type { FC, HTMLAttributes } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Collection } from '../../api';
import { Input, Select } from '../atoms';
import styles from './PhoneNumberField.module.css';

export const PhoneNumberField: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  const { setValue } = useFormContext();
  const { data: phonePrefixes } = useQuery(
    'phone-prefixes',
    Collection.phonePrefixes,
  );

  useEffect(() => {
    setValue('phonePrefix', phonePrefixes?.[0]?.code);
  }, [phonePrefixes, setValue]);

  return (
    <div className={classNames(styles.container, className)} {...rest}>
      <Select
        required
        className={styles.prefix}
        label="phone_prefix:label"
        name="phonePrefix"
        options={phonePrefixes?.map(phonePrefix => ({
          label: phonePrefix.code,
          value: phonePrefix.code,
        }))}
      />
      <Input
        required
        className={styles.number}
        label="phone_number:label"
        name="phoneNumber"
        type="tel"
      />
    </div>
  );
};
