import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { Overlay, Portal } from '..';
import { ReactComponent as CardIcon } from '../../assets/icons/card.svg';
import { ReactComponent as MessageIcon } from '../../assets/icons/message.svg';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { ReactComponent as ExitIcon } from '../../assets/icons/exit.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { RoutePath } from '../../config';
import styles from './SideBar.module.css';
import { useAuthContext } from '../../hooks';

interface Props {
  onClose(): void;
}

export const SideBar: FC<Props> = ({ onClose }) => {
  const { signOut } = useAuthContext();

  return (
    <>
      <Overlay onClick={onClose} />
      <Portal>
        <aside className={styles.container}>
          <button className={styles.close} onClick={onClose} type="button">
            <CloseIcon />
          </button>
          <nav>
            <NavLink className={styles.item} onClick={onClose} to={RoutePath.Profile}>
              <UserIcon />
              <Text id="profile" />
            </NavLink>
            <NavLink className={styles.item} onClick={onClose} to={RoutePath.Card}>
              <CardIcon />
              <Text id="card" />
            </NavLink>
            <NavLink className={styles.item} onClick={onClose} to={RoutePath.Contact}>
              <MessageIcon />
              <Text id="contact" />
            </NavLink>
            <button className={styles.item} onClick={signOut}>
              <ExitIcon />
              <Text id="exit" />
            </button>
          </nav>
        </aside>
      </Portal>
    </>
  );
};
