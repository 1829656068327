import { useEffect } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';

export interface RegisterFormFields {
  allConsent: boolean;
  attachment?: FileList;
  birthDay: string;
  birthMonth: string;
  birthYear: string;
  child: boolean;
  city: string;
  contribution: string;
  country: string;
  email: string;
  firstName: string;
  house: string;
  lastName: string;
  legalFirstName?: string;
  legalLastName?: string;
  newsletter: boolean;
  pesel: string;
  phoneNumber: string;
  phonePrefix: string;
  photo?: FileList;
  photoCrop?: File;
  postCode: string;
  regulations: boolean;
  regulationsSecondary: boolean;
  secondName?: string;
  street: string;
  structure: string;
  voivodeship: string;
}

export function useRegisteForm(): UseFormReturn<RegisterFormFields, object> {
  const form = useForm<RegisterFormFields>({
    shouldUseNativeValidation: false,
    defaultValues: {
      birthDay: '',
      birthMonth: '',
    },
  });
  const { watch, setValue } = form;
  const [child, newsletter, regulations, regulationsSecondary] = watch([
    'child',
    'newsletter',
    'regulations',
    'regulationsSecondary',
  ]);

  useEffect(() => {
    const subscription = watch((values, { name, type }) => {
      if (name === 'allConsent' && type === 'change') {
        const value = Boolean(values['allConsent']);
        setValue('newsletter', value);
        setValue('regulations', value, {
          shouldValidate: true,
        });
        setValue('regulationsSecondary', value, {
          shouldValidate: true,
        });
      }
    });

    return () => subscription.unsubscribe();
  }, [setValue, watch]);

  useEffect(() => {
    if (!child) {
      setValue('legalFirstName', undefined);
      setValue('legalLastName', undefined);
    }
  }, [child, setValue]);

  useEffect(() => {
    if (!newsletter || !regulations || !regulationsSecondary) {
      setValue('allConsent', false);
    } else {
      setValue('allConsent', true);
    }
  }, [newsletter, regulations, regulationsSecondary, setValue]);

  return form;
}
