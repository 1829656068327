import { UserMembership } from '../model';
import { HttpClient } from './HttpClient';

interface MembershipResponse {
  data: UserMembership[];
}

export class MembershipApi {
  static async fetch(): Promise<UserMembership[]> {
    const { data } = await HttpClient.getInstance().get<MembershipResponse>(
      '/member/structure',
    );

    return data.data;
  }
}
