import { useQuery } from 'react-query';
import { MembershipApi } from '../api';
import { StructureType } from '../model';

export const useActiveBranch = () => {
  const { data } = useQuery('user-membership', MembershipApi.fetch);

  return (
    data?.filter(
      ({ active, type_id }) => active && type_id === StructureType.Branch,
    ) || []
  );
};
