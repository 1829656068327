import { Text } from '@eo-locale/react';
import download from 'downloadjs';
import { FC, useCallback } from 'react';
import { CardApi } from '../api';
import { ReactComponent as UploadIcon } from '../assets/icons/upload.svg';
import {
  Card,
  InnerPage,
  InnerPageContent,
  InnerPageSection,
  Portal,
} from '../components';
import styles from './CardPage.module.css';

export const CardPage: FC = () => {
  const downloadCard = useCallback(async () => {
    try {
      const data = await CardApi.pdf();
      download(data, 'card.pdf', 'application/pdf');
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <InnerPage>
      <InnerPageContent className="relative">
        <InnerPageSection>
          <Portal containerId="mobile-header">
            <UploadIcon onClick={downloadCard} />
          </Portal>
          <button
            className={styles.download}
            onClick={downloadCard}
            type="button">
            <UploadIcon />
            <Text id="download" />
          </button>
          <div className={styles.card_wrapper}>
            <Card />
          </div>
        </InnerPageSection>
      </InnerPageContent>
    </InnerPage>
  );
};
