import { FC, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import styles from './CropImage.module.css';

interface Props {
  onComplete(crop: Crop): void;
  onImageLoaded(image: HTMLImageElement): void;
  initialCrop?: Crop;
  src: string;
}

export const CropImage: FC<Props> = ({
  onComplete,
  onImageLoaded,
  initialCrop = {
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    unit: '%',
  },
  src,
}) => {
  const [crop, setCrop] = useState<Crop>(initialCrop);

  return (
    <div className={styles.container}>
      <ReactCrop
        className="max-h-full"
        crop={crop}
        onChange={setCrop}
        onComplete={onComplete}
        onImageLoaded={onImageLoaded}
        src={src}
      />
    </div>
  );
};
