import { BooleanNumber } from '../model/BooleanNumber';
import { Nullable } from './../model/Nullable';
import { HttpClient } from './HttpClient';

interface CardResponse {
  card_number: string;
  expire: string;
  first_name: string;
  is_valid: boolean;
  last_name: string;
  qr: string;
  second_name: Nullable<string>;
  status: BooleanNumber;
}

export class CardApi {
  static async data(userId: string): Promise<CardResponse> {
    const { data } = await HttpClient.getInstance().get<CardResponse>(
      `/card/${userId}`,
    );

    return data;
  }

  static async pdf(): Promise<Blob> {
    const { data } = await HttpClient.getInstance().get<Blob>('/pdf', {
      responseType: 'blob',
    });

    return data;
  }
}
