import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, PaymentPage } from '../components';
import { RoutePath } from '../config';

export const PaymentFailedPage: FC = () => {
  const navigate = useNavigate();

  return (
    <PaymentPage
      title="payment_failed:title"
      subtitle="payment_failed:subtitle">
      <Button onClick={() => navigate(RoutePath.Checkout)}>
        <Text id="payment_failed:action" />
      </Button>
    </PaymentPage>
  );
};
