import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { useQuery } from 'react-query';
import { HistoryApi } from '../api';
import { InnerPageSection, InnerPageTitle, Table } from '../components';

export const HistoryPage: FC = () => {
  const { data } = useQuery('user-history', HistoryApi.fetch);

  return (
    <>
      {data?.authorityMain.data.map((authority, index) => (
        <InnerPageSection key={index}>
          <InnerPageTitle>{authority.name}</InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="comissions:function" />
                </th>
                <th>
                  <Text id="comissions:start_date" />
                </th>
                <th>
                  <Text id="comissions:finish_date" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{authority.function}</td>
                <td>{authority.start_date}</td>
                <td>{authority.end_date ?? '-'}</td>
              </tr>
            </tbody>
          </Table>
        </InnerPageSection>
      ))}
      {data?.globalAuthority.data.map((authority, index) => (
        <InnerPageSection key={index}>
          <InnerPageTitle>{authority.name}</InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="comissions:function" />
                </th>
                <th>
                  <Text id="comissions:start_date" />
                </th>
                <th>
                  <Text id="comissions:finish_date" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{authority.function}</td>
                <td>{authority.start_date}</td>
                <td>{authority.end_date ?? '-'}</td>
              </tr>
            </tbody>
          </Table>
        </InnerPageSection>
      ))}
      {data?.structureAuthority.data.map((authority, index) => (
        <InnerPageSection key={index}>
          <InnerPageTitle>{authority.name}</InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="comissions:function" />
                </th>
                <th>
                  <Text id="comissions:start_date" />
                </th>
                <th>
                  <Text id="comissions:finish_date" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{authority.function}</td>
                <td>{authority.start_date}</td>
                <td>{authority.end_date ?? '-'}</td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="comissions:permissions" />
                </th>
                <th>
                  <Text id="comissions:start_date" />
                </th>
                <th>
                  <Text id="comissions:finish_date" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{authority.authority}</td>
                <td>{authority.start_date}</td>
                <td>{authority.end_date ?? '-'}</td>
              </tr>
            </tbody>
          </Table>
        </InnerPageSection>
      ))}
      {data?.commissions.data.map((comission, index) => (
        <InnerPageSection key={index}>
          <InnerPageTitle>{comission.name}</InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="comissions:function" />
                </th>
                <th>
                  <Text id="comissions:start_date" />
                </th>
                <th>
                  <Text id="comissions:finish_date" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{comission.function}</td>
                <td>{comission.start_date}</td>
                <td>{comission.end_date ?? '-'}</td>
              </tr>
            </tbody>
          </Table>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="comissions:permissions" />
                </th>
                <th>
                  <Text id="comissions:start_date" />
                </th>
                <th>
                  <Text id="comissions:finish_date" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{comission.authority}</td>
                <td>{comission.start_date}</td>
                <td>{comission.end_date ?? '-'}</td>
              </tr>
            </tbody>
          </Table>
        </InnerPageSection>
      ))}
      {data?.globalPermissions.data.map((permission, index) => (
        <InnerPageSection key={index}>
          <InnerPageTitle>
            {permission.authority}
          </InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="permissions:name" />
                </th>
                <th>
                  <Text id="permissions:date" />
                </th>
                <th>
                  <Text id="permissions:id" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{permission.name}</td>
                <td>{'-'}</td>
                <td>{permission.identification_number}</td>
              </tr>
            </tbody>
          </Table>
        </InnerPageSection>
      ))}
      {data?.permissions.data.map((permission, index) => (
        <InnerPageSection key={index}>
          <InnerPageTitle>
            <Text id="permissions:title" />
          </InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="permissions:name" />
                </th>
                <th>
                  <Text id="permissions:date" />
                </th>
                <th>
                  <Text id="permissions:id" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{permission.name}</td>
                <td>{permission.date}</td>
                <td>{permission.identification_number}</td>
              </tr>
            </tbody>
          </Table>
        </InnerPageSection>
      ))}
      {data?.distinctions.data.map((distinction, index) => (
        <InnerPageSection key={index}>
          <InnerPageTitle>
            <Text id="distinctions:title" />
          </InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="distinctions:name" />
                </th>
                <th>
                  <Text id="distinctions:date" />
                </th>
                <th>
                  <Text id="distinctions:id" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{distinction.name}</td>
                <td>{distinction.date}</td>
                <td>{distinction.identification_number}</td>
              </tr>
            </tbody>
          </Table>
        </InnerPageSection>
      ))}
    </>
  );
};
