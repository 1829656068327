import classNames from 'classnames';
import type { FC, HTMLAttributes } from 'react';
import styles from './InnerPageTitle.module.css';

export const InnerPageTitle: FC<HTMLAttributes<HTMLHeadingElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <h2 className={classNames(styles.title, className)} {...rest}>
      {children}
    </h2>
  );
};
