import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { RoutePath } from '../../config';
import { InnerPageContent } from '../atoms';
import { InnerPage } from './InnerPage';
import styles from './ProfileLayout.module.css';

export const ProfileLayout: FC = () => {
  return (
    <InnerPage>
      <nav className={styles.menu}>
        <NavLink className={styles.item} to={RoutePath.PersonalData}>
          <Text id="personal_data" />
        </NavLink>
        <NavLink className={styles.item} to={RoutePath.Membership}>
          <Text id="membership" />
        </NavLink>
        <NavLink className={styles.item} to={RoutePath.Contributions}>
          <Text id="contributions" />
        </NavLink>
        <NavLink className={styles.item} to={RoutePath.History}>
          <Text id="history" />
        </NavLink>
      </nav>
      <InnerPageContent className={styles.content}>
        <Outlet />
      </InnerPageContent>
    </InnerPage>
  );
};
