import { Text } from '@eo-locale/react';
import { FC } from 'react';
import {
  InnerPageSection,
  InnerPageTitle,
  MembershipWidget,
  Money,
  Table,
} from '../components';
import { useUserContributions } from '../hooks';

export const ContributionsPage: FC = () => {
  const { active, history } = useUserContributions();

  return (
    <>
      {active && (
        <InnerPageSection>
          <InnerPageTitle>
            <Text id="contributions:active" />
          </InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="contribution:title" />
                </th>
                <th>
                  <Text id="contribution:price" />
                </th>
                <th>
                  <Text id="contribution:date" />
                </th>
                <th>
                  <Text id="contribution:expire" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{active.name}</td>
                <td>
                  <Money amount={active.value ?? 0} />
                </td>
                <td>{active.date}</td>
                <td>{active.expire}</td>
              </tr>
            </tbody>
          </Table>
          <MembershipWidget />
        </InnerPageSection>
      )}
      {history.length > 0 && (
        <InnerPageSection>
          <InnerPageTitle>
            <Text id="contributions:history" />
          </InnerPageTitle>
          <Table>
            <thead>
              <tr>
                <th>
                  <Text id="contribution:title" />
                </th>
                <th>
                  <Text id="contribution:price" />
                </th>
                <th>
                  <Text id="contribution:date" />
                </th>
                <th>
                  <Text id="contribution:expire" />
                </th>
              </tr>
            </thead>
            <tbody>
              {history.map((contribution, index) => (
                <tr key={index}>
                  <td>{contribution.name}</td>
                  <td>
                    <Money amount={contribution.value ?? 0} />
                  </td>
                  <td>{contribution.date}</td>
                  <td>{contribution.expire}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </InnerPageSection>
      )}
    </>
  );
};
