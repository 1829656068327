import { Text } from '@eo-locale/react';
import { FC, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Button, PaymentPage } from '../components';
import { RoutePath } from '../config';

export const PaymentSuccessfulPage: FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    queryClient.refetchQueries(['user', 'user-contributions']);
  }, [queryClient]);

  return (
    <PaymentPage
      title="payment_successful:title"
      subtitle="payment_successful:subtitle">
      <Button onClick={() => navigate(RoutePath.Profile)}>
        <Text id="payment_successful:action" />
      </Button>
    </PaymentPage>
  );
};
