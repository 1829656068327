import axios, { AxiosInstance } from 'axios';

export class HttpClient {
  private static readonly BASE_URL =
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API_URL
      : '/api';
  private static instance: AxiosInstance;

  private constructor() {}

  public static getInstance(): AxiosInstance {
    if (!HttpClient.instance) {
      HttpClient.instance = axios.create({
        baseURL: HttpClient.BASE_URL,
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      });
    }

    return HttpClient.instance;
  }

  public static setToken(token: string): void {
    HttpClient.instance = axios.create({
      baseURL: HttpClient.BASE_URL,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  public static clearToken(): void {
    HttpClient.instance = axios.create({
      baseURL: HttpClient.BASE_URL,
    });
  }
}
