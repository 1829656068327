import classNames from 'classnames';
import { FC, InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Checkbox.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

export const Checkbox: FC<Props> = ({
  children,
  className,
  name,
  required = false,
  ...rest
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <label className={classNames(styles.container, className)}>
      <input
        {...rest}
        {...register(name, {
          required,
        })}
        className={classNames(styles.input, {
          [styles.invalid]: Boolean(errors[name]),
        })}
        required={required}
        type="checkbox"
      />
      <span className={styles.checkbox} />
      {!!children && <span className={styles.label}>{children}</span>}
    </label>
  );
};
