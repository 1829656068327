import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { MembershipApi } from '../api';
import { StructureType, UserMembership } from '../model';

interface UserMembershipGroup {
  list: UserMembership[];
  title: string;
}

const isActiveMembership = (membership: UserMembership): boolean =>
  membership.active === 1;

const isInactiveMembership = (membership: UserMembership): boolean =>
  membership.active === 0;

const hasType =
  (type: StructureType) =>
  (membership: UserMembership): boolean =>
    membership.type_id === type;

export function useUserMembership(): {
  active: UserMembership[];
  groups: UserMembershipGroup[];
} {
  const { data: userMembership } = useQuery(
    'user-membership',
    MembershipApi.fetch,
  );

  const active: UserMembership[] = useMemo(() => {
    if (!Array.isArray(userMembership)) {
      return [];
    }

    return userMembership.filter(isActiveMembership);
  }, [userMembership]);

  const groups: UserMembershipGroup[] = useMemo(() => {
    if (!Array.isArray(userMembership)) {
      return [];
    }

    const branches: UserMembership[] = [];
    const clubs: UserMembership[] = [];
    const districts: UserMembership[] = [];

    for (const item of userMembership) {
      switch (item.type_id) {
        case StructureType.Branch:
          branches.push(item);
          break;
        case StructureType.Club:
          clubs.push(item);
          break;
        case StructureType.District:
          districts.push(item);
          break;
      }
    }

    const groups: UserMembershipGroup[] = [
      {
        list: userMembership
          .filter(hasType(StructureType.Branch))
          .filter(isActiveMembership),
        title: 'membership:branches',
      },
      {
        list: userMembership
          .filter(hasType(StructureType.District))
          .filter(isActiveMembership),
        title: 'membership:districts',
      },
      {
        list: userMembership
          .filter(hasType(StructureType.Club))
          .filter(isActiveMembership),
        title: 'membership:clubs',
      },
      {
        list: userMembership
          .filter(hasType(StructureType.Branch))
          .filter(isInactiveMembership),
        title: 'membership:branches_old',
      },
      {
        list: userMembership
          .filter(hasType(StructureType.District))
          .filter(isInactiveMembership),
        title: 'membership:districts_old',
      },
      {
        list: userMembership
          .filter(hasType(StructureType.Club))
          .filter(isInactiveMembership),
        title: 'membership:clubs_old',
      },
    ];

    return groups;
  }, [userMembership]);

  return {
    active,
    groups,
  };
}
