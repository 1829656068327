import { Text, useTranslator } from '@eo-locale/react';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { PaymentsApi } from '../api';
import { Button, Money, PaymentPage, Select } from '../components';
import { useContributions } from '../hooks';

interface FormFields {
  id: string;
}

export const CheckoutPage: FC = () => {
  const translator = useTranslator();
  const contributions = useContributions();
  const form = useForm<FormFields>();
  const id = form.watch('id');

  const selectedContribution = useMemo(
    () =>
      contributions?.find(contribution => contribution.id.toString() === id),
    [contributions, id],
  );

  useEffect(() => {
    if (selectedContribution === undefined && contributions) {
      form.setValue('id', contributions?.[0]?.id?.toString());
    }
  }, [contributions, form, selectedContribution]);

  const onSubmit: SubmitHandler<FormFields> = async ({ id }) => {
    try {
      const { redirect_url } = await PaymentsApi.pay(id);
      window.location.href = redirect_url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <PaymentPage isBackButtonVisible title="checkout:title">
      <FormProvider {...form}>
        <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
          <Select
            block
            required
            className="mb-4"
            label="checkout:type"
            name="id"
            options={contributions?.map(contribution => ({
              label: `N ${contribution.id} - ${contribution.type} - ${
                contribution.value
              } PLN - ważna do ${translator.formatDate(
                new Date(contribution.expire),
              )}`,
              value: contribution.id.toString(),
            }))}
          />

          {selectedContribution && (
            <div className="mb-1 flex flex-col md:flex-row gap-3 p-3 bg-gray-20 rounded-md">
              <div className="flex-1 text-left">
                <Text id="checkout:type" />
                <span className="block text-2xl">
                  {selectedContribution.type}
                </span>
              </div>
              <div className="text-left">
                <Text id="checkout:price" />
                <span className="block text-2xl">
                  <Money amount={selectedContribution.value} />
                </span>
              </div>
              <Button className="px-2.5" type="submit">
                <Text id="checkout:submit" />
              </Button>
            </div>
          )}

          <span className="text-sm">
            <Text id="checkout:helper" />
          </span>
        </form>
      </FormProvider>
    </PaymentPage>
  );
};
