import type { FC } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useStructures } from '../../hooks';
import { Select, SelectProps } from '../atoms';

export const StructureSelector: FC<Omit<SelectProps, 'name'>> = props => {
  const { setValue } = useFormContext();
  const structures = useStructures();

  useEffect(() => {
    setValue('structure', structures?.[0]?.id);
  }, [structures, setValue]);

  return (
    <Select
      {...props}
      block
      required
      label="structure"
      name="structure"
      options={structures?.map(structure => ({
        label: structure.name,
        value: structure.id.toString(),
      }))}
    />
  );
};
