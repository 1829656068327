import { Text, useTranslator } from '@eo-locale/react';
import type { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../config';
import { useMembershipExpiryDate } from '../../hooks';
import { Button } from '../atoms';

export const MembershipWidget: FC = () => {
  const translator = useTranslator();
  const navigate = useNavigate();
  const { expiryDate, isExpirySoon, isValid } = useMembershipExpiryDate();

  if (!expiryDate || !isValid || !isExpirySoon) {
    return null;
  }

  const diff = expiryDate.getTime() - Date.now();
  const diffDays = Math.ceil(diff / (1_000 * 3600 * 24));

  if (diffDays <= 0) {
    return null;
  }

  return (
    <div className="my-3 flex flex-col gap-2 md:flex-row md:items-center md:justify-between p-2.5 rounded-xl bg-gray-30">
      <span>
        <h3 className="mb-1 text-2xl">
          <Text id="membership:make_payment" />
        </h3>
        <Text
          html
          date={translator.formatDate(expiryDate)}
          days={diffDays}
          id="membership:warning"
        />
      </span>
      <Button
        className="px-2.5"
        onClick={() => {
          navigate(RoutePath.Checkout);
        }}>
        <Text id="membership:make_payment" />
      </Button>
    </div>
  );
};
