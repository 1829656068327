import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { UserApi } from '../../api';
import { ReactComponent as UndoIcon } from '../../assets/icons/undo.svg';
import { Button, Portal } from '../atoms';
import styles from './RejectEditWidget.module.css';

export const RejectEditWidget: FC = () => {
  const queryClient = useQueryClient();
  const { data: user } = useQuery('user', UserApi.fetch);
  const { mutate: undo } = useMutation(() => UserApi.rejectEdit(), {
    onSuccess() {
      queryClient.invalidateQueries('user');
    },
  });

  if (!user || !user.pending_changes || user.pending_changes.length === 0) {
    return null;
  }

  return (
    <>
      <Portal containerId="mobile-header">
        <UndoIcon onClick={() => undo()} />
      </Portal>

      <div className={styles.container}>
        <p className={styles.content}>
          <Text id="user:edit_reject_description" />
        </p>
        <Button
          className={styles.undo}
          onClick={() => undo()}
          theme="secondary">
          <UndoIcon />
          <Text id="user:reject_edit" />
        </Button>
      </div>
    </>
  );
};
