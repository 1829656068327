import type { FC } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useCountries } from '../../hooks';
import { Select, SelectProps } from '../atoms';

export const CountrySelector: FC<Omit<SelectProps, 'name'>> = props => {
  const { setValue } = useFormContext();
  const countries = useCountries();

  useEffect(() => {
    setValue('country', countries?.[0]?.code);
  }, [countries, setValue]);

  return (
    <Select
      {...props}
      block
      required
      label="country"
      name="country"
      options={countries?.map(country => ({
        label: country.name,
        value: country.code,
      }))}
    />
  );
};
