import { Structure } from '../model/Structure';
import { Contribution, Country, PhonePrefix, Voivodeship } from '../model';
import { HttpClient } from './HttpClient';

type CountriesResponse = Record<string, string>;
type VoivodeshipsResponse = Record<string, string>;
type PhonePrefixesResponse = Record<string, string>;
interface StructuresResponse {
  data: Structure[];
}

export class Collection {
  static async structures(): Promise<Structure[]> {
    const { data } = await HttpClient.getInstance().get<StructuresResponse>(
      '/structure',
      {
        params: {
          per_page: 100,
        },
      },
    );

    return data.data;
  }

  static async contributions(): Promise<Contribution[]> {
    const { data } = await HttpClient.getInstance().get<Contribution[]>(
      '/collections',
    );

    return data;
  }

  static async countries(): Promise<Country[]> {
    const { data } = await HttpClient.getInstance().get<CountriesResponse>(
      '/countries',
    );

    const countries: Country[] = [];

    for (const [key, value] of Object.entries(data)) {
      countries.push({
        code: key,
        name: value,
      });
    }

    return countries;
  }

  static async voivodeships(): Promise<Voivodeship[]> {
    const { data } = await HttpClient.getInstance().get<VoivodeshipsResponse>(
      '/voivodeships',
    );

    const voivodeships: Voivodeship[] = [];

    for (const [key, value] of Object.entries(data)) {
      voivodeships.push({
        code: key,
        name: value,
      });
    }

    return voivodeships;
  }

  static async phonePrefixes(): Promise<PhonePrefix[]> {
    const { data } = await HttpClient.getInstance().get<PhonePrefixesResponse>(
      '/phone-prefixes',
    );

    const prefixes: PhonePrefix[] = [];

    for (const [key, value] of Object.entries(data)) {
      prefixes.push({
        code: key,
        name: value,
      });
    }

    return prefixes;
  }
}
