import { Text } from '@eo-locale/react';
import classNames from 'classnames';
import { FC, SelectHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Select.module.css';

export interface Option {
  disabled?: boolean;
  hidden?: boolean;
  label: string;
  value: string;
}

export interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  block?: boolean;
  label?: string;
  name: string;
  options?: ReadonlyArray<Option>;
}

export const Select: FC<SelectProps> = ({
  block = false,
  children,
  className,
  label,
  name,
  options = [],
  required = false,
  ...rest
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <label
      className={classNames(
        styles.container,
        {
          [styles.block]: block,
        },
        className,
      )}>
      {!!label && (
        <span className={styles.label}>
          <Text id={label} />
          {required && '*'}
        </span>
      )}
      <select
        {...rest}
        {...register(name, {
          required,
        })}
        required={required}
        className={classNames(styles.select, {
          [styles.invalid]: Boolean(errors[name]),
        })}>
        {options.map(option => (
          <option
            hidden={option.hidden}
            disabled={option.disabled}
            key={option.value}
            value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </label>
  );
};
