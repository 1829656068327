import Axios from 'axios';
import { useCallback } from 'react';
import { FieldValues, Path, UseFormSetError } from 'react-hook-form';
import { ErrorResponse } from '../api';

type KeyMapper<TFieldValues extends FieldValues = FieldValues> = (
  key: string,
) => Path<TFieldValues>;

const defaultKeyMapper = <TFieldValues extends FieldValues = FieldValues>(
  key: string,
) => key as Path<TFieldValues>;

export function useApiErrorHandler<
  TFieldValues extends FieldValues = FieldValues,
>(
  setError: UseFormSetError<TFieldValues>,
  mapKey: KeyMapper<TFieldValues> = defaultKeyMapper,
) {
  const errorHandler = useCallback(
    (error: unknown) => {
      if (Axios.isAxiosError(error)) {
        const response: ErrorResponse | undefined = error.response?.data;
        console.error(response);
        for (const [key, errors] of Object.entries(response?.errors ?? {})) {
          if (errors.length > 0) {
            setError(
              mapKey(key),
              {
                type: 'validate',
                message: errors[0],
              },
              {
                shouldFocus: true,
              },
            );
          }
        }
      }
    },
    [mapKey, setError],
  );

  return errorHandler;
}
