import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import {
  ContactForm,
  InnerPage,
  InnerPageContent,
  InnerPageSection,
} from '../components';

export const ContactPage: FC = () => {
  return (
    <InnerPage>
      <InnerPageContent>
        <InnerPageSection>
          <h3>
            <Text id="contact:subtitle" />
          </h3>
          <ContactForm />
        </InnerPageSection>
      </InnerPageContent>
    </InnerPage>
  );
};
