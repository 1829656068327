import { Nullable } from './../model/Nullable';
import { HttpClient } from './HttpClient';

export interface HistoryResponse {
  permissions: {
    data: Array<{
      name: string;
      date: string;
      identification_number: string;
    }>;
  };
  distinctions: {
    data: Array<{
      name: string;
      date: string;
      identification_number: string;
    }>;
  };
  globalPermissions: {
    data: Array<{
      authority: string;
      end_date: Nullable<string>;
      identification_number: string;
      name: string;
    }>;
  };
  authorityMain: {
    data: Array<{
      deleted_at: Nullable<string>;
      end_date: Nullable<string>;
      function: string;
      name: string;
      start_date: string;
    }>;
  };
  structureAuthority: {
    data: Array<{
      authority: string;
      deleted_at: Nullable<string>;
      end_date: Nullable<string>;
      function: string;
      name: string;
      start_date: string;
    }>;
  };
  commissions: {
    data: Array<{
      name: string;
      authority: string;
      function: string;
      start_date: string;
      end_date: Nullable<string>;
      deleted_at: Nullable<string>;
    }>;
  };
  globalAuthority: {
    data: Array<{
      deleted_at: Nullable<string>;
      end_date: Nullable<string>;
      function: string;
      name: string;
      permission: string;
      start_date: string;
    }>;
  };
}

export class HistoryApi {
  static async fetch(): Promise<HistoryResponse> {
    const { data } = await HttpClient.getInstance().get<HistoryResponse>(
      '/member/history',
    );

    return data;
  }
}
