import classNames from 'classnames';
import { FC, HTMLAttributes } from 'react';
import { Input } from '../atoms';
import styles from './AddressField.module.css';

export const CityField: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <div className={classNames(styles.container, className)} {...rest}>
      <Input required className="flex-1" name="city" label="city" />
      <Input
        required
        className={styles.small}
        label="post_code:label"
        mask="99-999"
        name="postCode"
      />
    </div>
  );
};
