import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { useQuery } from 'react-query';
import { UserApi } from '../../api';
import Logo from '../../assets/logo.svg';
import { useCard, useActiveBranch } from '../../hooks';
import { usePhoto } from '../../hooks/usePhoto';
import styles from './Card.module.css';

export const Card: FC = () => {
  const { data: user } = useQuery('user', UserApi.fetch);
  const card = useCard(user?.uuid);
  const photo = usePhoto(user?.image);
  const [activeBranch] = useActiveBranch();

  if (!card) {
    return null;
  }

  const name = [card.first_name, card.second_name, card.last_name]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={styles.container}>
      <div className="flex justify-between">
        <img alt="User" src={photo} className={styles.photo} />
        <img alt="Logo" className={styles.logo} src={Logo} />
      </div>
      <div className="flex justify-between mt-4 items-end">
        <ul className={styles.list}>
          <li className={styles.item}>
            <p className={styles.name}>
              <Text id="card:name" />
            </p>
            <strong>{name}</strong>
          </li>
          <li className={styles.item}>
            <p className={styles.name}>
              <Text id="card:id" />
            </p>
            <strong>{card.card_number}</strong>
          </li>
          <li className={styles.item}>
            <p className={styles.name}>
              <Text id="card:expire" />
            </p>
            <strong>{card.expire}</strong>
          </li>
          <li className={styles.item}>
            <p className={styles.name}>
              <Text id="card:active_branch" />
            </p>
            <strong>{activeBranch.name}</strong>
          </li>
        </ul>
        <img alt="QR code" className={styles.qr} src={card.qr} />
      </div>
    </div>
  );
};
