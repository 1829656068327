import { Text } from '@eo-locale/react';
import classNames from 'classnames';
import { FC, InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Crop } from 'react-image-crop';
import { ReactComponent as PlusIcon } from '../../assets//icons/plus.svg';
import { CropImage, Modal, ModalRef } from '../organisms';
import styles from './PhotoField.module.css';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  mode: 'add' | 'edit';
  invalid?: boolean;
}

export const PhotoField: FC<Props> = ({
  children,
  className,
  invalid = false,
  mode = 'add',
  ...rest
}) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [crop, setCrop] = useState<Crop>();
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [img, setImg] = useState<string>('');
  const [preview, setPreview] = useState<string>('');
  const modalRef = useRef<ModalRef | null>(null);
  const {
    formState: { errors },
    register,
    setValue,
    watch,
  } = useFormContext();
  const photo: FileList | undefined = watch('photo');

  useEffect(() => {
    if (photo && photo.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImg(reader.result as string);
        setPreview(reader.result as string);
      });
      reader.readAsDataURL(photo[0]);
    } else {
      imgRef.current = null;
      setImg('');
      setPreview('');
      setCrop(undefined);
    }
  }, [photo]);

  useEffect(() => {
    if (!crop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d')!;
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );

    const photoFile = photo![0];
    canvas.toBlob(
      blob => {
        if (!blob) {
          return;
        }

        setValue('photoCrop', new File([blob], photoFile.name));
      },
      photoFile.type,
      1,
    );
    setPreview(canvas.toDataURL());
  }, [crop, photo, setValue]);

  const error = errors['photo'];

  return (
    <>
      <canvas className="hidden" ref={previewCanvasRef} />
      <Modal ref={modalRef}>
        <CropImage
          initialCrop={crop}
          onComplete={setCrop}
          onImageLoaded={img => (imgRef.current = img)}
          src={img}
        />
      </Modal>
      <div className={classNames(styles.container, className)}>
        {Boolean(preview) ? (
          <img
            alt="Preview"
            className={classNames(styles.preview, {
              [styles.invalid]: Boolean(error),
            })}
            src={preview}
          />
        ) : (
          <label
            htmlFor="photo"
            className={classNames(styles.label, {
              [styles.invalid]: Boolean(error),
            })}>
            <PlusIcon />
            <Text id="upload_photo" />
          </label>
        )}
        <input
          {...register('photo', {
            required: true,
            validate: {
              lessThan3MB: files =>
                files[0]?.size < 3_000_000 || 'Max photo size is 3MB',
            },
          })}
          {...rest}
          id="photo"
          className={styles.input}
          type="file"
          accept=".jpg, .png, .jpeg"
        />
        <span className={styles.description}>
          <Text
            id={
              mode === 'edit' ? 'photo:description_edit' : 'photo:description'
            }
          />
          {Boolean(photo && photo.length > 0) && (
            <div className={styles.actions}>
              <button
                className={styles.action}
                type="button"
                onClick={() => modalRef.current?.open()}>
                <Text id="photo:crop" />
              </button>
              <button
                className={styles.action}
                type="button"
                onClick={() => setValue('photo', undefined)}>
                <Text id="photo:remove" />
              </button>
            </div>
          )}
          {!!error && <span className={styles.error}>{error.message}</span>}
        </span>
      </div>
    </>
  );
};
