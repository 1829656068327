import { Text, useTranslator } from '@eo-locale/react';
import classNames from 'classnames';
import { FC, ReactNode, TextareaHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import styles from './Textarea.module.css';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  block?: boolean;
  label?: string;
  name: string;
  requiredMark?: ReactNode;
}

export const Textarea: FC<Props> = ({
  block = false,
  children,
  className,
  label,
  maxLength,
  minLength,
  name,
  placeholder,
  required = false,
  requiredMark = '*',
  ...rest
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();
  const translator = useTranslator();

  const errorMessage = errors[name]?.message;

  return (
    <label
      className={classNames(
        styles.container,
        {
          [styles.block]: block,
        },
        className,
      )}>
      {!!label && (
        <span className={styles.label}>
          <Text id={label} />
          {required && requiredMark}
        </span>
      )}
      <textarea
        {...rest}
        {...register(name, {
          required,
          minLength,
          maxLength,
        })}
        className={classNames(styles.textarea, {
          [styles.invalid]: Boolean(errors[name]),
        })}
        required={required}
        placeholder={
          placeholder ? translator.translate(placeholder) : undefined
        }
      />
      {!!errorMessage && <span className={styles.error}>{errorMessage}</span>}
    </label>
  );
};
