import { Text } from '@eo-locale/react';
import classNames from 'classnames';
import { ChangeEvent, ClipboardEvent, FC, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import styles from './CodeField.module.css';

const CODE_LENGTH = 6;
const cells = Array.from(Array(CODE_LENGTH).keys());

export const CodeField: FC = () => {
  const {
    clearErrors,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const cellRefs = useRef<Array<HTMLInputElement | null>>([]);
  const code = watch('code');
  const values = code.split('');

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const data = e.clipboardData.getData('text');
    clearErrors('code');
    setValue('code', data);
    const activeIndex =
      data.length >= CODE_LENGTH ? CODE_LENGTH - 1 : data.length;
    window.setTimeout(() => cellRefs.current[activeIndex]?.focus());
  };

  const handleChange =
    (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
      clearErrors('code');
      const { value } = e.target;

      if (value.length === 0 && index > 0) {
        cellRefs.current[index - 1]?.focus();
      }

      if (value.length > 0 && index < CODE_LENGTH) {
        cellRefs.current[index + 1]?.focus();
      }

      values[index] = value;
      setValue('code', values.join(''));
    };

  const invalid = Boolean(errors['code']);

  return (
    <div className={styles.container}>
      {cells.map(index => (
        <InputMask
          key={index}
          mask="9"
          maskPlaceholder={null}
          onPaste={index === 0 ? handlePaste : undefined}
          onChange={handleChange(index)}
          value={values[index] ?? ''}>
          <input
            ref={el => (cellRefs.current[index] = el)}
            className={classNames(styles.cell, {
              [styles.invalid]: invalid,
            })}
          />
        </InputMask>
      ))}
      {invalid && (
        <span className={styles.helper}>
          <Text id="code:invalid" />
        </span>
      )}
    </div>
  );
};
