import { FC, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { LoginCodeStep } from './LoginCodeStep';
import { LoginEmailStep } from './LoginEmailStep';

export type LoginFormStep = 'email' | 'code';

export interface LoginFormFields {
  code: string;
  email: string;
}

interface Props {
  onChangeStep(step: LoginFormStep): void;
}

export const LoginForm: FC<Props> = ({ onChangeStep }) => {
  const [step, setStep] = useState<LoginFormStep>('email');
  const form = useForm<LoginFormFields>({
    defaultValues: {
      code: '',
      email: '',
    },
  });

  useEffect(() => {
    onChangeStep(step);
  }, [onChangeStep, step]);

  return (
    <FormProvider {...form}>
      {step === 'email' ? (
        <LoginEmailStep onSuccess={() => setStep('code')} />
      ) : (
        <LoginCodeStep />
      )}
    </FormProvider>
  );
};
