import { Text } from '@eo-locale/react';
import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import { useCard } from '../hooks';

export const CardStatusPage: FC = () => {
  const { userId } = useParams<{ userId: string }>();
  const card = useCard(userId);

  if (!card) {
    return null;
  }

  return (
    <>{card.is_valid ? <Text id="card:valid" /> : <Text id="card:invalid" />}</>
  );
};
