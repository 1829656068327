import { Text } from '@eo-locale/react';
import { FC, useCallback, useEffect } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ErrorResponse, HttpClient, UserApi } from '../../api';
import { RegisterFormFields, useApiErrorHandler, useModal } from '../../hooks';
import { Button, Checkbox } from '../atoms';
import { NewsletterModal } from '../molecules';
import styles from './EditPersonalDataForm.module.css';
import { UserForm } from './UserForm';

interface Props {
  onSuccess(): void;
}

export const EditPersonalDataForm: FC<Props> = ({ onSuccess }) => {
  const queryClient = useQueryClient();
  const newsletterModal = useModal();
  const { data: user } = useQuery('user', UserApi.fetch);
  const { mutate, error } = useMutation<
    void,
    ErrorResponse,
    RegisterFormFields
  >(data => UserApi.edit(data), {
    onSuccess() {
      queryClient.invalidateQueries('user');
      onSuccess();
    },
  });
  const [birthYear, birthMonth, birthDay] = user?.birthdate.split('-') ?? [];
  const form = useForm<RegisterFormFields>({
    defaultValues: {
      birthDay: String(Number(birthDay)),
      birthMonth: String(Number(birthMonth)),
      birthYear,
      city: user?.city,
      email: user?.email,
      firstName: user?.first_name,
      lastName: user?.last_name,
      legalFirstName: user?.legal_first_name ?? undefined,
      legalLastName: user?.legal_last_name ?? undefined,
      newsletter: Boolean(user?.newsletter),
      pesel: user?.pesel,
      phoneNumber: user?.phone,
      phonePrefix: user?.phone_prefix,
      postCode: user?.post_code,
      secondName: user?.second_name ?? undefined,
      street: user?.street,
      voivodeship: user?.voivodeship,
    },
  });
  const { handleSubmit, setError, setValue } = form;
  const keyMapper = useCallback(
    (key: string) =>
      key === 'phone' ? 'phoneNumber' : (key as keyof RegisterFormFields),
    [],
  );
  const handleApiError = useApiErrorHandler(setError, keyMapper);

  const fetchPhoto = useCallback(async () => {
    if (!user?.image) {
      return;
    }

    const imageUrl = new URL(user.image);
    const { data: response } = await HttpClient.getInstance().get(
      `${imageUrl.pathname.replace('/api', '')}${imageUrl.search}`,
      {
        responseType: 'blob',
      },
    );
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(new File([response], 'temp.png'));
    setValue('photo', dataTransfer.files);
    const photoInput = document.getElementById('photo');
    if (photoInput && photoInput instanceof HTMLInputElement) {
      photoInput.files = dataTransfer.files;
      photoInput.dispatchEvent(new Event('change'));
    }
  }, [user?.image, setValue]);

  useEffect(() => {
    fetchPhoto();
  }, [fetchPhoto]);

  useEffect(() => {
    handleApiError(error);
  }, [handleApiError, error]);

  const onSubmit: SubmitHandler<RegisterFormFields> = async data => {
    mutate(data);
  };

  return (
    <FormProvider {...form}>
      <NewsletterModal child={user?.child === '1'} ref={newsletterModal} />
      <form
        noValidate
        className={styles.container}
        onSubmit={handleSubmit(onSubmit)}>
        <UserForm mode="edit" />
        <Checkbox name="newsletter">
          Chcę otrzymywac{' '}
          <span
            onClick={e => {
              e.preventDefault();
              newsletterModal.current?.open();
            }}
            className="underline">
            Newsletter
          </span>
        </Checkbox>
        <footer className={styles.footer}>
          <Button type="submit">
            <Text id="save" />
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};
