import type { FC } from 'react';

export const PhotoRegulations: FC = () => {
  return (
    <>
      <h1 className="mb-4 text-xl text-center font-bold">
        KLAUZULA ZGODY NA WYKORZYSTANIE WIZERUNKU
      </h1>
      <p className="mb-2">
        Wyrażam zgodę na wykorzystywanie mojego wizerunku w celu promocji
        działalności PTTK na oficjalnej stronie internetowej oraz portalach
        społecznościowych.
      </p>
      <p className="mb-2 underline">Rozwija się:</p>
      <p className="mb-2">
        Zgodnie z art. 13 ust. 1 i ust. 2 ogólnego rozporządzenia o ochronie
        danych osobowych z dnia 27 kwietnia 2016 r. (dalej RODO) informujemy,
        że:
      </p>
      <ul className="ml-4 mb-4 list-disc list-inside">
        <li>
          Współadministratorami Pani/Pana danych osobowych jest:
          <ul className="ml-4 list-disc list-inside">
            <li>
              Polskie Towarzystwo Turystyczno-Krajoznawcze (zwane dalej również
              „PTTK”) z siedzibą w Warszawie przy ul. Senatorskiej 11, wpisane
              do rejestru stowarzyszeń Krajowego Rejestru Sądowego pod numerem
              100817,
            </li>
            <li>Oddział PTTK do którego Pan/Pani należy.</li>
          </ul>
        </li>
        <li>
          Stowarzyszenie jako administrator powołał Inspektora Ochrony Danych -
          Pawła Sternika, z którym kontakt możliwy jest poprzez następujący
          adres e-mailowy:{' '}
          <a className="underline" href="mailto:iod@pttk.pl">
            iod@pttk.pl
          </a>{' '}
          lub poprzez adresy pocztowe.
        </li>

        <li>
          Pani/Pana dane osobowe- wizerunek- przetwarzane będą w celu promocji
          działalności PTTK na oficjalnej stronie internetowej oraz portalach
          społecznościowych, na podstawie zgody- art. 6 ust. 1 lit. a RODO.
        </li>

        <li>
          Odbiorcą Pani/Pana danych osobowych będą pracownicy Administratora
          oraz członkowie oddziałów ogólnopolskich PTTK w zakresie swoich
          obowiązków służbowych na podstawie upoważnienia, w ramach działalności
          statutowej PTTK.
        </li>
        <li>
          Pani/Pana dane osobowe nie będą przekazywane do państwa
          trzeciego/organizacji.
        </li>
        <li>
          Pani/Pana dane osobowe będą przechowywane do czasu cofnięcia zgody.
        </li>
        <li>
          Posiada Pani/Pan prawo dostępu do treści swoich danych oraz prawo ich
          sprostowania, usunięcia, ograniczenia przetwarzania, prawo do
          przenoszenia danych, prawo wniesienia sprzeciwu.
        </li>
        <li>
          Posiada Pani/Pan prawo do cofnięcia zgody w dowolnym momencie bez
          wpływu na zgodność z prawem przetwarzania, którego dokonano na
          podstawie zgody przed jej cofnięciem.
        </li>
        <li>
          Posiada Pani/Pan prawo wniesienia skargi do organu nadzorczego -
          Prezesa Urzędu Ochrony Danych Osobowych, gdy uzna Pani/Pan, iż
          przetwarzanie danych osobowych Pani/Pana dotyczących narusza przepisy
          ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia
          2016 r.
        </li>
        <li>
          Pani/Pana dane nie będą przetwarzane w sposób zautomatyzowany w tym
          również w formie profilowania, tzn. żadne decyzje wywołujące wobec
          osoby skutki prawne lub w podobny sposób na nią istotnie wpływające
          nie będą oparte wyłącznie na automatycznym przetwarzaniu danych
          osobowych i nie wiążą się z taką automatycznie podejmowaną decyzją.
        </li>
      </ul>
    </>
  );
};
