import { useQuery } from 'react-query';
import { ContributionsApi } from '../api';
import { Contribution, UserContribution } from '../model';
import { useContributions } from './useContributions';

export function useUserContributions(): {
  active?: UserContribution;
  history: UserContribution[];
  userContribution?: Contribution;
} {
  const contributions = useContributions();
  const { data } = useQuery('user-contributions', ContributionsApi.fetch);
  const active = data?.find(item => item.active);
  const history = data?.filter(item => !item.active) ?? [];
  const userContribution =
    contributions?.find(item => item.type === active?.name) ||
    contributions?.find(item => item.type === history[0]?.name);

  return {
    active,
    history,
    userContribution,
  };
}
